







import { Component, Prop, Vue } from 'vue-property-decorator'
import { postShowLogin } from '@/utils/events'

@Component
export default class LoginNotification extends Vue {
  @Prop() episodeUid!: any

  get brandColor() {
    return this.$store.getters.brandColor
  }

  handleLogin() {
    postShowLogin(this.episodeUid)
  }

  mounted() {
    const ele: any = document.getElementsByClassName('Vue-Toastification__toast--default')
    for (let i = 0; i < ele.length; i++) {
      ele[i].style.backgroundColor = this.brandColor
    }
  }
}
