


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class Confirmation extends Vue {
  @Prop({ default: '' }) readonly title!: string
  @Prop({ default: '' }) readonly primaryButtonText!: string
  @Prop({ default: '' }) readonly secondaryButtonText!: string
  @Prop({ default: () => {} }) readonly onClick!: Function

  @Getter brandColor!: string
}
