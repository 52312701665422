




















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { getNextTagPosition, getPrevTagPosition } from '@/utils/tag-utils'
import Poll from '@/components/Poll.vue'
import Analytics from '@/utils/analytics'
import AdoriService from '@/services/adori'
import ERRORS from '@/utils/errorMessages'

const landscape = () => import('@/styles/AdoriTag/Landscape.css')

@Component({
  components: {
    Poll,
  },
})
export default class NavigateTag extends Vue {
  @Prop(Object) tag!: any
  @Prop(Array) tagPositions!: any
  @Prop(Number) currentTime!: number

  @Getter brandColor!: string
  @Getter experiencesById!: any

  @Action getExperience!: any

  created() {
    this.$route.name === 'landscape' && landscape()
  }

  fontClasses = ['rubik', 'unica-one', 'permanent-marker', 'marck-script', 'dosis']
  showPoll = false
  choiceSelected = null
  pollSubmitted = false

  get caption() {
    return this.tag.caption || this.tag.notetext || (this.tag.buy && this.tag.buy.name) || null
  }

  get link() {
    switch (this.tag.actions) {
      case 'navigate':
        return `https://www.google.com/maps/place/${(this.tag.location || this.tag.locationInfo).location.replace(
          'geo:',
          ''
        )}`
      case 'choose':
        return 'poll-init'
      case 'call':
        return `tel:${this.tag.contact.phoneNumber}`
      case 'click':
        return this.tag.url
      case 'display_ad':
        return this.tag.displayAd && this.tag.displayAd.actionUrl
      case 'buy':
        return this.tag.buy.actionUrl
    }
    return ''
  }

  get saveable() {
    return this.tag.saveable
  }
  get shareable() {
    return this.tag.shareable
  }

  get captionFontClass() {
    const fontStyle = this.tag.style.fontStyle
    const index = (fontStyle === 0 ? 1 : fontStyle) - 1
    return this.fontClasses[index]
  }
  get captionMarginPercentage() {
    return 'bottom: 60px'
  }
  get hasNextTag() {
    let tagPosition = getNextTagPosition(this.tagPositions, this.currentTime)
    if (tagPosition !== undefined) {
      return true
    } else {
      return false
    }
  }
  get hasPrevTag() {
    let tagPosition = getPrevTagPosition(this.tagPositions, this.currentTime)
    if (tagPosition !== undefined) {
      return true
    } else {
      return false
    }
  }
  get showNextTag() {
    return this.hasNextTag
  }
  get showPrevTag() {
    return this.hasPrevTag
  }
  get tagButtonClass() {
    switch (this.tag.actions) {
      case 'navigate':
        return 'locate'
      case 'choose':
        return 'poll-init'
      case 'call':
        return 'contact'
      case 'click':
        return this.tag.url ? 'link' : 'like'
      case 'display_ad':
        return this.tag.displayAd && this.tag.displayAd.actionUrl ? 'link' : 'like'
      case 'buy':
        return this.tag.buy && this.tag.buy.actionText ? this.tag.buy.actionText : 'Buy'
    }
    return ''
  }
  get tagExperience() {
    return this.experiencesById(this.tag.expId)
  }
  get tagInteraction() {
    return this.tagExperience && this.tagExperience.interaction
  }
  get pollStatus() {
    return this.tagInteraction && this.tagInteraction.pollStatus
  }
  get likeStatus() {
    return this.tagInteraction && this.tagInteraction.likeStatus
  }
  get getUniqueTagDiv() {
    return 'ad_tag_' + Math.random().toString(36).substr(2, 5)
  }

  nextTag() {
    let tagPosition = getNextTagPosition(this.tagPositions, this.currentTime)
    if (tagPosition !== undefined) {
      let seekTo = tagPosition.offsetMillis / 1000
      // if (seekTo === this.seekTo) {
      //   seekTo = seekTo + 0.01;
      // }
      this.$store.dispatch('seekAudio', seekTo)
    }
  }

  prevTag() {
    let tagPosition = getPrevTagPosition(this.tagPositions, this.currentTime)
    if (tagPosition !== undefined) {
      let seekTo = tagPosition.offsetMillis / 1000
      // if (seekTo === this.seekTo) {
      //   seekTo = seekTo + 0.01;
      // }
      this.$store.dispatch('seekAudio', seekTo)
    }
  }

  onActionClick() {
    this.$emit('locate')
    if (this.link) {
      window.open(this.link, '_blank')
    }
  }

  async getCurrentExperience() {
    this.getExperience(this.tag.expId)
  }

  postTagInteracted() {
    Analytics.postTagInteracted(this.tag)
  }

  setTagExperience(experience) {
    this.$store.dispatch('setExperience', experience)
  }

  async submitPoll(option) {
    try {
      const interaction = (await AdoriService.submitPoll(this.tag.expId, option)).data
      this.setTagExperience({ ...this.tagExperience, interaction })
    } catch (error) {
      if (error && error.status === 409) {
        const interaction = error.data
        this.setTagExperience({ ...this.tagExperience, interaction })
        console.log(ERRORS.pollAlreadySubmitted)
      }
    }
    await this.postTagInteracted()
  }

  async handleLike() {
    try {
      let interaction = null
      if (this.likeStatus) {
        interaction = (await AdoriService.unlikeAnExperience(this.tag.expId)).data
      } else {
        interaction = (await AdoriService.likeAnExperiece(this.tag.expId)).data
      }
      this.setTagExperience({ ...this.tagExperience, interaction })
    } catch (error) {}
    await this.postTagInteracted()
  }

  async handleNothing() {
    this.postTagInteracted()
  }

  async handleNavigation() {
    this.postTagInteracted()
  }

  async handleCallAction() {
    this.postTagInteracted()
  }

  async handleTagAction() {
    switch (this.tag.actions) {
      case 'navigate':
        this.handleNavigation()
        break
      case 'call':
        this.handleCallAction()
        break
      case 'click':
        this.handleLike()
        break
      case 'display_ad':
        this.handleLike()
        break
      case 'buy':
        this.handleNavigation()
        break
    }
  }
  mounted() {}
}
