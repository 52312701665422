




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BrandedNotification extends Vue {
  @Prop(String) info!: string

  get brandColor() {
    return this.$store.getters.brandColor
  }

  mounted() {
    const ele: any = document.getElementsByClassName('Vue-Toastification__toast--default')
    for (let i = 0; i < ele.length; i++) {
      ele[i].style.backgroundColor = this.brandColor
    }
  }
}
