












































































































































































































































































































































import { Mixins, Component, Watch, Prop } from 'vue-property-decorator'
import Analytics from '@/utils/analytics'
import Events from '@/utils/events'
import { mixin as clickaway } from 'vue-clickaway'
import LoginNotification from '@/components/LoginNotification.vue'
import BrandedNotification from '@/components/BrandedNotification.vue'
import { Getter } from 'vuex-class'

@Component({
  filters: {
    formatTime(value) {
      if (value === null) {
        return '--:--'
      }
      let intValue = Math.round(value)
      let minutes = Math.floor(intValue / 60)
      let seconds = Math.floor(intValue % 60)
      let minString = minutes.toString().padStart(2, '0')
      let secString = seconds.toString().padStart(2, '0')
      return `${minString}:${secString}`
    },
    millisToSec(value) {
      return value === null ? null : value / 1000
    },
  },
})
export default class Controls extends Mixins(clickaway) {
  showRateChange = false
  fullscreen = false
  showShare = false
  showEpisodes: any = null
  displayEpisodeDesc = false
  displayShowDesc = false
  displayPlaylist = false
  showAdvancedButtons = false
  seekBarTime = 0
  allEvents = []
  previousOffset = 0
  advancedControls = ''
  rate = 1.0
  clipWidth = 'display: none'

  @Prop() full!: boolean
  @Prop() controls!: boolean
  @Prop() seekBar!: boolean

  @Getter playing!: boolean
  @Getter srcAdded!: boolean
  @Getter currentTime!: any
  @Getter audio!: any
  @Getter duration!: any
  @Getter allowBookmarks!: any
  @Getter allowClips!: any
  @Getter allowPlaylist!: any
  @Getter allowShare!: any
  @Getter allowAdvancedControls!: any
  @Getter allowLike!: any
  @Getter episode!: any
  @Getter startTime!: any
  @Getter stopTime!: any
  @Getter isPlaylisted!: any
  @Getter isFirstTimePlay!: any
  @Getter isLiked!: any
  @Getter isBookmarkClip!: any
  @Getter allowOtherEpisodes!: any
  @Getter isOtherEpisodesVisible!: any
  @Getter userId!: any
  @Getter isBrandedSite!: any
  @Getter brandColor!: any

  get allowLayerTwoControls() {
    return this.allowBookmarks || this.allowClips || this.allowShare
  }
  get progressPercentage() {
    if (this.currentTime === null || this.duration === null) {
      return 0
    }
    return (this.currentTime / this.duration) * 100
  }
  get rateString() {
    if (parseInt(`${this.rate}`) === this.rate) return `${this.rate}.0x`
    return `${this.rate}x`
  }
  get advancedLandscapeButtons() {
    return [
      {
        icon: 'list',
        display: this.allowOtherEpisodes,
        onClick: this.viewOtherEpisodes,
        name: 'Other Episodes',
      },
      {
        icon: this.isLiked ? 'favorite' : 'favorite_border',
        display: this.allowLike,
        onClick: this.handleEpisodeLike,
        name: this.isLiked ? 'Unlike Episode' : 'Like Episode',
      },
      {
        icon: this.isPlaylisted ? 'playlist_add_check' : 'playlist_add',
        display: this.allowPlaylist,
        onClick: this.hanldePlaylisted,
        name: this.isPlaylisted ? 'Remove from Playlist' : 'Add to Playlist',
      },
      {
        icon: 'bookmark_border',
        display: this.allowBookmarks,
        onClick: this.handleBookmarked,
        name: 'Bookmark',
      },
      {
        icon: 'content_cut',
        display: this.allowClips,
        onClick: this.handleClipped,
        name: 'Create 30s clip',
      },
      {
        icon: 'subject',
        display: true,
        onClick: this.handleEpisodeDetails,
        name: 'Episode Details',
      },
      {
        icon: 'reply',
        display: this.allowShare,
        onClick: this.handleShare,
        name: 'Share',
      },
    ]
  }
  replayAudio() {
    let newCurrentTime = this.currentTime - 10
    this.$store.dispatch('seekAudio', newCurrentTime)
  }

  forwardAudio() {
    let newCurrentTime = this.currentTime + 10
    this.$store.dispatch('seekAudio', newCurrentTime)
  }
  playAudio() {
    if (this.srcAdded) {
      this.audio
        .play()
        .then((_) => {
          if (this.isFirstTimePlay) {
            Analytics.startAnalyticsPoll()
            this.$store.dispatch('setIsFirstTimePlay', false)
          }
        })
        .catch((reason) => {
          console.error('Cannot play audio: ', reason)
          console.log(reason.message)
        })
    }
  }
  pauseAudio() {
    this.audio.pause()
  }
  handleAdvancedControls() {
    this.showAdvancedButtons = !this.showAdvancedButtons
    this.advancedControls = this.showAdvancedButtons ? 'advanced-controls-enter' : 'advanced-controls-leave'
  }
  showLogin() {
    this.$toast({
      component: LoginNotification,
      props: {
        episodeUid: this.episode.uid,
      },
    })
  }
  showBrandedNotification(info) {
    this.$toast({
      component: BrandedNotification,
      props: {
        info: info,
      },
    })
  }

  get fullscreenEnabled() {
    const ios = /iPad|iPhone|iPod/.test(navigator.userAgent)
    if (ios) {
      return false
    } else {
      return this.full
    }
  }
  handleClipped() {
    if (!this.userId) this.showLogin()
    else {
      let stopTime = this.currentTime + 30
      stopTime = stopTime < this.duration ? stopTime : this.duration
      Events.postClipped(this.episode.uid, this.currentTime, stopTime)
      this.showBrandedNotification('30 second audio clip created')
      this.handleAdvancedControls()
    }
  }
  handleBookmarked() {
    if (!this.userId) this.showLogin()
    else {
      Events.postBookmarked(this.episode.uid, this.currentTime)
      this.showBrandedNotification('Episode bookmarked at this point')
      this.handleAdvancedControls()
    }
  }
  hanldePlaylisted() {
    if (!this.userId) this.showLogin()
    else {
      const playlisted = !this.isPlaylisted
      Events.postPlaylisted(this.episode.uid, playlisted)
      this.$store.dispatch('setEventData', {
        key: 'isPlaylisted',
        value: playlisted,
      })
      if (playlisted) this.showBrandedNotification('Added to playlist')
      this.handleAdvancedControls()
    }
  }
  handleLiked() {
    if (!this.userId) this.showLogin()
    else Events.postLiked(this.episode.uid, true)
  }
  changePlaybackRate(rate) {
    this.$store.dispatch('changePlaybackRate', rate)
  }
  handleAudioRate() {
    switch (this.rate) {
      case 1.0:
        this.rate = 1.5
        this.changePlaybackRate(1.5)
        break
      case 1.5:
        this.rate = 2.0
        this.changePlaybackRate(2.0)
        break
      case 2.0:
        this.rate = 0.5
        this.changePlaybackRate(0.5)
        break
      case 0.5:
        this.rate = 1.0
        this.changePlaybackRate(1.0)
        break
    }
  }
  handleEpisodeDetails() {
    Events.postOpenEpisode(this.episode.uid)
    this.$emit('toggleDisplayEpisodeDesc')
    this.handleAdvancedControls()
  }
  away() {
    if (this.showAdvancedButtons) this.handleAdvancedControls()
  }
  handleShare() {
    if (!this.isBrandedSite) this.$emit('toggleShowShare')
    Events.postOnShare(this.episode.uid)
    this.handleAdvancedControls()
  }
  handleShareL() {
    if (!this.isBrandedSite) this.$emit('toggleShowShare')
    Events.postOnShare(this.episode.uid)
  }
  openFullScreen() {
    try {
      if (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (!document['mozFullScreen'] && !document['webkitIsFullScreen'])
      ) {
        this.fullscreen = true
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement['mozRequestFullScreen']) {
          /* Firefox */
          document.documentElement['mozRequestFullScreen']()
        } else if (document.documentElement['webkitRequestFullScreen']) {
          /* Chrome, Safari & Opera */
          document.documentElement['webkitRequestFullScreen']()
        } else if (document['msRequestFullscreen']) {
          /* IE/Edge */
          document.documentElement['msRequestFullscreen']()
        }
      }
    } catch (e) {
      this.showBrandedNotification('Full screen not supported')
    }
  }
  handleFullScreenL() {
    this.openFullScreen()
  }

  closeFullScreen() {
    try {
      if (document['cancelFullScreen']) {
        document['cancelFullScreen']()
      } else if (document['mozCancelFullScreen']) {
        /* Firefox */
        document['mozCancelFullScreen']()
      } else if (document['webkitCancelFullScreen']) {
        /* Chrome, Safari and Opera */
        document['webkitCancelFullScreen']()
      } else if (document['msExitFullscreen']) {
        /* IE/Edge */
        document['msExitFullscreen']()
      }
      this.fullscreen = false
    } catch (e) {
      this.showBrandedNotification('Full screen not supported')
    }
  }
  handleEpisodeLike() {
    const liked = !this.isLiked
    Events.postLiked(this.episode.uid, liked)
    this.$store.dispatch('setEventData', { key: 'isLiked', value: liked })
    if (liked) this.showBrandedNotification('Added to Liked')
    this.handleAdvancedControls()
  }
  setClipWidth() {
    if (this.startTime && this.isBookmarkClip) {
      const leftOffset = (this.startTime * 100) / this.duration
      if (this.stopTime !== -1) {
        const clipDuration = this.stopTime - this.startTime
        const width = (clipDuration * 100) / this.duration
        this.clipWidth = `left: ${leftOffset}%; width: ${width}%`
      } else {
        this.clipWidth = `left:${leftOffset}%; width: 3px; height: 8px; top:-2px`
      }
    } else this.clipWidth = 'display: none;'
  }
  viewOtherEpisodes() {
    const otherEpisodes = !this.isOtherEpisodesVisible
    this.$store.dispatch('setEventData', {
      key: 'isOtherEpisodesVisible',
      value: otherEpisodes,
    })
    this.handleAdvancedControls()
  }

  checkFull() {
    if (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      document['webkitFullscreenElement'] ||
      document['mozFullScreenElement']
    ) {
      this.fullscreen = true
    } else {
      this.fullscreen = false
    }
  }

  mounted() {
    this.seekBarTime = this.progressPercentage
    document.addEventListener(
      'webkitfullscreenchange',
      (e) => {
        this.checkFull()
      },
      false
    )

    document.addEventListener(
      'mozfullscreenchange',
      (e) => {
        this.checkFull()
      },
      false
    )

    document.addEventListener(
      'fullscreenchange',
      (e) => {
        this.checkFull()
      },
      false
    )
  }

  @Watch('seekBarTime')
  seekBarTimeWatcher(newVal, oldVal) {
    // this.postSeekEvent(newVal, oldVal, this.duration, this.currentTime, this.episode)
    let seekTime = (newVal * this.duration) / 100
    if (Math.abs(seekTime - this.currentTime) > 1) {
      this.$store.dispatch('setCurrentTime', seekTime)
      this.$store.dispatch('seekAudio', seekTime)
      this.previousOffset = Math.floor((newVal * this.duration) / 100)
    }
  }

  @Watch('progressPercentage')
  progressPercentageWatcher(newVal, oldVal) {
    this.seekBarTime = newVal
  }

  @Watch('duration')
  durationWatcher(newVal, oldVal) {
    this.setClipWidth()
  }
}
