







import { Vue, Prop, Component } from 'vue-property-decorator'
import get from 'lodash.get'

const landscape = () => import('@/styles/AdoriTag/Landscape.css')

@Component
export default class TagBackground extends Vue {
  @Prop(Object) audiogram!: any
  @Prop(String) src!: string

  created() {
    this.$route.name === 'landscape' && landscape()
  }
  get videoSrc() {
    return get(this.audiogram, 'video.originalUrl')
  }
  get videoOrImage() {
    if (get(this.audiogram, 'video.id')) {
      return true
    } else {
      return false
    }
  }
}
