import axios from 'axios'
import { ADORI_API_V6_STUDIO_URL } from '@/constants'
import axiosRetry from 'axios-retry'

const retryDelay = (retryNumber = 0) => {
  return retryNumber * 5000
}

export default {
  fetchAudiogramOnTrack(networkId, audiotrackId) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audiotrackId}/audiograms`)
  },

  fetchAudiogramById(networkId, audiogramId) {
    return AdoriAPI.get(`/networks/${networkId}/audiograms/${audiogramId}`)
  },
}

export const AdoriAPI = axios.create({
  baseURL: ADORI_API_V6_STUDIO_URL,
})

axiosRetry(AdoriAPI, {
  retries: 2,
  retryDelay,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError,
})

AdoriAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('id_token')

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

AdoriAPI.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const response = error.response
    if (!response) {
      return Promise.reject(new Error('No response.'))
    } else if (error && error.response && error.response.status === 500) {
      return Promise.reject(new Error(error.response || 'Something went wrong.'))
    }
    throw response
  }
)
