











































import { Component, Vue } from 'vue-property-decorator'
// import Analytics from '@/utils/analytics.js'
import { mapGetters } from 'vuex'
import { ADORI_PODCASTS_BASE_URL } from '@/constants'
import BrandedNotification from '@/components/BrandedNotification.vue'
import { Getter } from 'vuex-class'

@Component
export default class Share extends Vue {
  @Getter episode!: any

  get iframePortrait() {
    let frameSrc = this.episodeURL
    return frameSrc
      ? `<iframe src='${frameSrc}' style="min-height: 650px; max-width: 380px" \
width='100%' height='100%' title="Adori web Player" frameborder='0' \
scrolling='no' allow='autoplay' allowfullscreen></iframe>`
      : ''
  }
  get iframeLandscape() {
    let frameSrc = this.episodeURL
    return frameSrc
      ? `<iframe src='${frameSrc}' style="min-width: 610px; max-height: 304px" \
width='100%' height='304' title="Adori web Player" frameborder='0' \
scrolling='no' allow='autoplay' allowfullscreen></iframe>`
      : ''
  }
  get episodeURL() {
    return this.episode ? `${window.location.protocol}//${window.location.host}/${this.episode.uid}` : ''
  }
  get shareURL() {
    return `${ADORI_PODCASTS_BASE_URL}/s/e?eid=${this.episode.uid}`
  }
  get shareList() {
    return [
      {
        id: 'portrait-player',
        name: 'Embed portrait player',
        value: this.iframePortrait,
      },
      {
        id: 'landscape-player',
        name: 'Embed landscape player',
        value: this.iframeLandscape,
      },
      {
        id: 'share-url',
        name: 'Share',
        value: this.shareURL,
      },
    ]
  }

  showBrandedNotification(info) {
    this.$toast({
      component: BrandedNotification,
      props: {
        info: info,
      },
    })
  }

  copyEmbed(id) {
    const ele: any = document.getElementById(id)
    const windorRef: any = window
    ele.select()
    document.execCommand('copy')
    this.showBrandedNotification('Copied to clipboard')
    windorRef.getSelection().removeAllRanges()
  }

  postToTwitter() {
    let text = this.episode.name
    let twitterUrl = `https://twitter.com/intent/tweet?text=${text}\
&url=${this.shareURL}`
    window.open(twitterUrl, '_blank')
    // Analytics.postShared('Twitter')
  }

  postToLinkedIn() {
    let linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/\
?url=${this.shareURL}`
    window.open(linkedinUrl, '_blank')
    // Analytics.postShared('LinkedIn')
  }

  postToFacebook() {
    let title = this.episode.name
    let description = this.episode.description
    let facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.shareURL}\
&title=${title}&description=${description}`
    window.open(facebookUrl, '_blank')
    // Analytics.postShared('Facebook')
  }
}
