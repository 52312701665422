
















































import { Component, Vue } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import AdoriService from '@/services/adori'
import { postOpenEpisode } from '@/utils/events'
import { Getter } from 'vuex-class'

@Component({
  components: {
    Loading,
  },
  filters: {
    formatTime(value) {
      if (value === null) {
        return '--:--'
      }
      let intValue = Math.round(value)
      let minutes = Math.floor(intValue / 60)
      let seconds = Math.floor(intValue % 60)
      let minString = minutes.toString().padStart(2, '0')
      let secString = seconds.toString().padStart(2, '0')
      return `${minString}:${secString}`
    },
    millisToSec(value) {
      return value === null ? null : value / 1000
    },
  },
})
export default class Playlist extends Vue {
  @Getter episode!: any

  next = null
  isLoading = false
  noEpisodes = false
  episodes: any = []
  isLoadingMore = false
  showLoadMore = true

  get currentEpisodesUid() {
    return this.episode && this.episode.uid
  }
  get show() {
    return (
      this.episode && this.episode.collections && this.episode.length !== 0 && this.episode.collections[0].collection
    )
  }
  get audio() {
    return this.$store.getters.audio
  }

  async getEpisodes() {
    this.isLoadingMore = true
    try {
      const episodes = (await AdoriService.fetchShowEpisodes(this.show.uid, this.next)).data
      if (episodes.audioTracks.length < 10) this.showLoadMore = false
      this.next = episodes.next
      this.episodes = [...this.episodes, ...episodes.audioTracks]
    } catch (error) {
      this.noEpisodes = true
    }
    this.isLoadingMore = false
  }

  handleClose() {
    this.$store.dispatch('setEventData', {
      key: 'isOtherEpisodesVisible',
      value: false,
    })
  }

  handleEpisode(uid) {
    if (this.currentEpisodesUid !== uid) {
      this.handleClose()
      this.audio.pause()
      this.$store.dispatch('setStartTime', 0)
      postOpenEpisode(uid)
      this.$router.push(`/${uid}`)
    }
  }

  async mounted() {
    this.isLoading = true
    await this.getEpisodes()
    this.isLoading = false
  }
}
