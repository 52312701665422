





import { Component, Prop, Vue } from 'vue-property-decorator'
const landscape = () => import('@/styles/AdoriTag/Landscape.css')

@Component
export default class DfpSlot extends Vue {
  @Prop(Object) tag!: any
  @Prop(Object) episode!: any
  @Prop(Array) size!: any
  @Prop(String) id!: string
  @Prop(String) src!: string

  slotServed = true
  adSlot!: any

  created() {
    this.$route.name === 'landscape' && landscape()
  }

  beforeCreate() {
    const ref: any = window
    ref.googletag.cmd.push(() => {
      ref.googletag.destroySlots()
    })
  }

  get adUnitPath() {
    return `/${this.tag.displayAd.adSource.adNetworkId}/` + `${this.tag.displayAd.adSource.accessCode}`
  }
  get screenSize() {
    return window.innerWidth + 'x' + window.innerHeight
  }

  refreshSlot() {
    const ref: any = window
    ref.googletag.pubads().refresh([this.adSlot])
  }

  formatSize(size) {
    if (Array.isArray(size)) {
      return size
    }
    if (typeof size === 'string') {
      return size.split('x').map((value) => parseInt(value, 10))
    }
    return []
  }

  mounted() {
    const ref: any = window

    if (!ref.googletag) {
      return
    }

    if (this.tag.displayAd.adServiceSource !== 'THIRD_PARTY') {
      return
    }
    const { size, id } = this
    if (this.tag.displayAd.adSource.name === 'GOOGLE') {
      ref.googletag.cmd.push(() => {
        const adSlot = ref.googletag
          .defineSlot(this.adUnitPath, size, id)
          .addService(ref.googletag.pubads())
          .setTargeting('network_id', this.tag.displayAd.networkId)

        if (typeof this.tag.displayAd.defaultCampaign !== 'undefined' && this.tag.displayAd.defaultCampaign !== null) {
          if (
            typeof this.tag.displayAd.defaultCampaign.keywords !== 'undefined' &&
            this.tag.displayAd.defaultCampaign.keywords !== null
          ) {
            for (var j = 0; j < this.tag.displayAd.defaultCampaign.keywords.length; j++) {
              var [key, value] = this.tag.displayAd.defaultCampaign.keywords[j].split(':')
              adSlot.setTargeting(key.trim(), [value.trim().split(',')])
            }
          }

          if (
            typeof this.tag.displayAd.defaultCampaign.geoTargets !== 'undefined' &&
            this.tag.displayAd.defaultCampaign.geoTargets !== null
          ) {
            for (j = 0; j < this.tag.displayAd.defaultCampaign.geoTargets.length; j++) {
              ref.googletag.pubads().setLocation(this.tag.displayAd.defaultCampaign.geoTargets[j])
            }
          }
        }

        ref.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          if (event.slot.getSlotElementId() === id) {
            if (event.isEmpty) {
              this.slotServed = false
            } else {
              this.slotServed = true
            }
          }
          // this.slotServed = true
        })

        ref.googletag.pubads().enableSingleRequest()
        ref.googletag.enableServices()
        ref.googletag.display(id)

        this.adSlot = adSlot
      })
    }
  }

  beforeDestroy() {
    const ref: any = window
    if (!ref.googletag) {
      return
    }
    // Destroy ad slot
    ref.googletag.cmd.push(() => {
      ref.googletag.destroySlots([this.adSlot])
    })
  }
}
