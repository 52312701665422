













































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { postOnResize } from '@/utils/events'
import { Getter } from 'vuex-class'
import get from 'lodash.get'

const legacyLandscape = () => import('@/styles/Player/Legacylandscape.css')
const landscape = () => import('@/styles/Player/Landscape.css')

@Component({
  filters: {
    formatTime(value) {
      if (value === null) {
        return '--:--'
      }
      let intValue = Math.round(value)
      let minutes = Math.floor(intValue / 60)
      let seconds = Math.floor(intValue % 60)
      let minString = minutes.toString().padStart(2, '0')
      let secString = seconds.toString().padStart(2, '0')
      return `${minString}:${secString}`
    },
    millisToSec(value) {
      return value === null ? null : value / 1000
    },
  },
})
export default class MiniPlayer extends Vue {
  @Prop() currentTag!: any

  @Getter playing!: boolean
  @Getter episode!: any
  @Getter isFirstTimePlay!: any
  @Getter duration!: any
  @Getter currentTime!: any
  @Getter audio!: any
  @Getter srcAdded!: boolean
  @Getter brandColor!: any
  @Getter isBookmarkClip!: any
  @Getter startTime!: any
  @Getter stopTime!: any

  seekBarTime = 0
  clipWidth = 'display: none'
  previousOffset = 0
  miniSeekBar = false

  created() {
    this.$route.name === 'landscape' ? legacyLandscape() : landscape()
  }

  get getUniqueTagDiv() {
    return this.episode.name + '_' + Math.random().toString(36).substr(2, 5)
  }
  get tagImage() {
    if (!this.currentTag) return ''

    if (this.currentTag.actions === 'display_ad') {
      return this.episode.imageUrl ? this.episode.imageUrl : this.episode.image ? this.episode.image.urls.regular : ''
    }
    return this.currentTag.imageInfo
      ? this.currentTag.imageInfo.url
      : this.currentTag.image
      ? this.currentTag.image.urls.small
      : ''
  }
  get progressPercentage() {
    if (this.currentTime === null || this.duration === null) {
      return 0
    }
    return (this.currentTime / this.duration) * 100
  }
  get episodeImage() {
    return this.episode.imageUrl ? this.episode.imageUrl : this.episode.image ? this.episode.image.urls.regular : ''
  }
  get currentTagActions() {
    return this.currentTag && this.currentTag.actions
  }
  get buttonText() {
    switch (this.currentTagActions) {
      case 'click':
        return this.currentTag && this.currentTag.url ? 'Visit link' : 'View Image'
      case 'choose':
        return 'Take poll'
      case 'create_note':
        return 'Read Note'
      case 'navigate':
        return 'Locate'
      case 'call':
        return 'Call Now'
      case 'buy':
        return this.currentTag.buy && this.currentTag.buy.actionText ? this.currentTag.buy.actionText : 'BUY'
      default:
        return 'Open'
    }
  }

  mounted() {
    this.miniSeekBar = get(this.$route.query, 'miniSeekBar', 0) === '1'
    this.seekBarTime = this.progressPercentage
  }

  toReadableDate(time) {
    const date = new Date(time).toString()
    return date.slice(date.indexOf(' ')).slice(0, date.indexOf(':') - 5)
  }
  truncateName() {
    if (this.currentTag && this.currentTag.caption) {
      return this.currentTag.caption
    } else if (this.currentTag && this.currentTag.buy && this.currentTag.buy.name) {
      return this.currentTag.buy.name
    } else {
      return this.episode.name
    }
  }
  playAudio() {
    if (this.srcAdded) {
      this.audio
        .play()
        .then(() => {
          if (this.isFirstTimePlay) {
            // Analytics.startAnalyticsPoll()
            this.$store.dispatch('setIsFirstTimePlay', false)
          }
        })
        .catch((reason) => {
          console.error('Cannot play audio: ', reason)
          console.log(reason.message)
        })
    }
  }
  pauseAudio() {
    this.audio.pause()
  }
  handlePlayerAction() {
    postOnResize(false)
  }

  setClipWidth() {
    if (this.startTime && this.isBookmarkClip) {
      const leftOffset = (this.startTime * 100) / this.duration
      if (this.stopTime !== -1) {
        const clipDuration = this.stopTime - this.startTime
        const width = (clipDuration * 100) / this.duration
        this.clipWidth = `left: ${leftOffset}%; width: ${width}%`
      } else {
        this.clipWidth = `left:${leftOffset}%; width: 3px; height: 8px; top:-2px`
      }
    } else this.clipWidth = 'display: none;'
  }

  @Watch('seekBarTime')
  seekBarTimeWatcher(newVal, oldVal) {
    let seekTime = (newVal * this.duration) / 100
    if (Math.abs(seekTime - this.currentTime) > 1) {
      this.$store.dispatch('setCurrentTime', seekTime)
      this.$store.dispatch('seekAudio', seekTime)
      this.previousOffset = Math.floor((newVal * this.duration) / 100)
    }
  }

  @Watch('progressPercentage')
  progressPercentageWatcher(newVal, oldVal) {
    this.seekBarTime = newVal
  }

  @Watch('duration')
  durationWatcher(newVal, oldVal) {
    this.setClipWidth()
  }
}
